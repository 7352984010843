import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

function Description(props) {
    return (
        <>
            <div className="about-wrapper pt--200 pb--120 bg_color--1" id="mine-description">
                <div className="container">
                    <div className="row align-items-center d-flex">
                        <div className="col-lg-6 col-xl-6 col-md-12 order-1 order-lg-1 order-xl-0">
                            <div className="thumbnail">
                                <StaticImage
                                    src="../../assets/images/rudnik/vodeni-ogledi-glavnega-rudnika.jpg"
                                    alt="A unique insight into the diversity of geological heritage in the Sitarjevec mine"
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 text-center order-lg-0 order-xl-1 col-md-12 col-xl-6 order-0 mb-4 mb-md-4 mb-lg-0">
                            <div className="p-2 p-lg-4">
                                <div className="section-title text-center">
                                    <h2 className="title">A unique insight into the diversity of the geological heritage</h2>
                                    <p className="description">The Sitarjevec Litija mine is a modern underground museum, that offers its visitors an insight into the diversity of the geological natural heritage, the world of bats, spiders and fungi, into the richness of the mining tradition and the interpretation of its richness through the eyes of artists.</p>

                                    <p className="description">The Sitarjevec mine is one of the polymineral ores with an exceptional mineral diversity. It is one of the richest deposits of minerals on a European and global scale. It contains more than sixty different minerals, the most important ore minerals among them are galena, sphalerite, cinnabar, chalcopyrite and baryte.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-wrapper pb--120">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="text-center p-2 p-lg-4">
                                <div className="section-title">
                                    <h2 className="title">The magical world of Litija’s underground</h2>
                                    <p className="description">
                                        In addition to the picturesque minerals, the visitors can be enchanted by the exceptional limonite dripstones created by the centuries of natural underground processes. Stalagmites, stalactites, stalactite curtains, stalactite spaghetti of the Sitarjevec mine, which grow up to 5 centimetres per year, are extremely unique in Europe. Here is the only known area of growing limonite dripstones in the Central Europe.
                                    </p>

                                    <p className="description">
                                        The magical world of Litija’s underground has been inspired various artists lately. They can create with pigments obtained on the basis of minerals from the Sitarjevec mine, they are inspired by the period of formation of mine rocks, the exceptional colourfulness of the mine, especially the lustre of Sitarjevec minerals. In the mine visitors can admire fascinating creations of many artists.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-xl-6">
                            <div className="thumbnail">
                                <StaticImage
                                    src="../../assets/images/rudnik/kapnik-v-rudniku-sitarjevec.jpg"
                                    alt="Stalactite in the Sitarjevec mine"
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Description;