import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

function Characteristics(props) {
    return (
        <div>
            {/* Start Service Area  */}
            <div className="service-area ptb--120 bg_color--5 mine-characteristics">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <h2 className="title">
                                    Explore the specifics <br /> of the Sitarjevec mine
                                </h2>
                                <p className="description">
                                    A unique insight into the diversity of the geological heritage
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-12 mt--30">
                            <div className="row service-main-wrapper d-flex">
                                {/*number 1 */}

                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 text-center mine-characteristics-element">
                                    <Link to={"/en/explore-the-mine"}>
                                        <div className="service service__style--2 text-center h-100">
                                            <StaticImage
                                                src="../../assets/images/features-icons/1-60-vrst-mineralov.png"
                                                alt="Icon for 60 different types of minerals in a mine Sitarjevec"
                                                loading="lazy"
                                                placeholder="blurred"
                                                imgClassName="img-fluid w-100"
                                            />
                                            <div className="content">
                                                <h3 className="title text-uppercase">
                                                    60 DIFFERENT TYPES OF MINERALS
                                                </h3>
                                                <p>
                                                    The Sitarjevec mine is one of the ore deposits with an exceptional mineral diversity, perhaps the largest in Europe
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>


                                {/*number 2 */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 text-center mine-characteristics-element">
                                    <Link to={"/en/explore-the-mine"}>
                                        <div className="service service__style--2 text-center h-100">
                                            <StaticImage
                                                src="../../assets/images/features-icons/2-limonitni-kapniki.png"
                                                alt="Liminite stalactites in the Litija Sitarjevec Mine"
                                                loading="lazy"
                                                placeholder="blurred"
                                                imgClassName="img-fluid w-100"
                                            />

                                            <div className="content">
                                                <h3 className="title text-uppercase">
                                                    LIMONITE DRIPSTONES
                                                </h3>
                                                <p>
                                                    The mine is distinguished by exceptional limonite dripstones, unique in Europe and perhaps the fastest growing in the world.
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                {/*number 3 */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 text-center mine-characteristics-element">
                                    <Link to={"/en/explore-the-mine"}>
                                        <div className="service service__style--2 text-center">
                                            <StaticImage
                                                src="../../assets/images/features-icons/3-barvitost-rudnika.png"
                                                alt="Colorfulness in the Litija Mine"
                                                loading="lazy"
                                                placeholder="blurred"
                                                imgClassName="img-fluid w-100"
                                            />
                                            <div className="content">
                                                <h3 className="title text-uppercase">
                                                    EXCEPTIONAL COLORING OF THE MINE
                                                </h3>
                                                <p>
                                                    The distinctive color of the rocks and minerals and their shiny reflections enchant visitors and inspire artists.
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                {/*number 4 */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 text-center mine-characteristics-element">
                                    <Link to={"/en/explore-the-mine"}>
                                        <div className="service service__style--2 text-center">
                                            <StaticImage
                                                src="../../assets/images/features-icons/4-gorotvorni-procesi.png"
                                                alt="Gorotvorni procesin in tektonska premikanja v Rudniku Litije"
                                                loading="lazy"
                                                placeholder="blurred"
                                                imgClassName="img-fluid w-100"
                                            />
                                            <div className="content">
                                                <h3 className="title text-uppercase">
                                                    MOUNTAIN FORMATION AND TECTONIC SLIDING
                                                </h3>
                                                <p>
                                                    Signs of 300 million years of almost unbelievable tectonic events.
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                {/*number 5 */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 text-center mine-characteristics-element">
                                    <Link to={"/en/explore-the-mine"}>
                                        <div className="service service__style--2 text-center">
                                            <StaticImage
                                                src="../../assets/images/features-icons/5-paleookolje.png"
                                                alt="Gorotvorni procesin in tektonska premikanja v Rudniku Litije"
                                                loading="lazy"
                                                placeholder="blurred"
                                                imgClassName="img-fluid w-100"
                                            />
                                            <div className="content h-100">
                                                <h3 className="title text-uppercase">
                                                    Reconstruction of the paleoenvironment
                                                </h3>
                                                <p>
                                                    An insight into the mysterious and seemingly fairy-tale world formation of rocks in Rudnik Sitarjevec.
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                {/*number 6 */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 text-center mine-characteristics-element">
                                    <Link to={"/en/explore-the-mine"}>
                                        <div className="service service__style--2 text-center">
                                            <StaticImage
                                                src="../../assets/images/features-icons/6-najstarejsi-rudnik.png"
                                                alt="Najstarejši Rudnik v Litiji "
                                                loading="lazy"
                                                placeholder="blurred"
                                                imgClassName="img-fluid w-100"
                                            />
                                            <div className="content">
                                                <h3 className="title text-uppercase">
                                                    THE OLDEST SLOVENIAN MINE?
                                                </h3>
                                                <p>
                                                    Archaeological sites in the vicinity indicate that mining in Litija was carried out 4000 years ago
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                {/*number 7 */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 text-center mine-characteristics-element">
                                    <Link to={"/en/explore-the-mine"}>
                                        <div className="service service__style--2 text-center">
                                            <StaticImage
                                                src="../../assets/images/features-icons/7-mali-podkovnjak.png"
                                                alt="Mali podkovnjak v rudniku Sitarjevec"
                                                loading="lazy"
                                                placeholder="blurred"
                                                imgClassName="img-fluid w-100"
                                            />

                                            <div className="content">
                                                <h3 className="title text-uppercase">
                                                    THE LESSER HORSESHOE BAT- RHINOLOPHUS HIPPOSIDEROS
                                                </h3>
                                                <p>
                                                    Bats are the only mammals that fly. The mine has always been their residence.
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                {/*number 8 */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 text-center mine-characteristics-element">
                                    <Link to={"/en/explore-the-mine"}>
                                        <div className="service service__style--2 text-center">
                                            <StaticImage
                                                src="../../assets/images/features-icons/8-topilnica.png"
                                                alt="Melting Factory Litija"
                                                loading="lazy"
                                                placeholder="blurred"
                                                imgClassName="img-fluid w-100"
                                            />

                                            <div className="content">
                                                <h3 className="title text-uppercase">
                                                    MELTING FACTORY AND BEEKEEPING JUSTICE
                                                </h3>
                                                <p>
                                                    The fight of Litija beekeepers against the Smeltery Litija. It is the first successful ecological lawsuit in Slovenia.
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 text-center mine-characteristics-element">
                                    <Link to={"/en/explore-the-mine"}>
                                        <div className="service service__style--2 text-center">
                                            <StaticImage
                                                src="../../assets/images/features-icons/9-srebrnik.png"
                                                alt="Silven coin"
                                                loading="lazy"
                                                placeholder="blurred"
                                                imgClassName="img-fluid w-100"
                                            />
                                            <div className="content">
                                                <h3 className="title text-uppercase">
                                                    SILVER COIN – LITIJA'S TOLAR
                                                </h3>
                                                <p>
                                                    In the Smeltery Litija, they obtained silver from lead ore from which they made commemorative silver coins – Litija’s tolars.
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Characteristics;
