import React from 'react';
import {Link, useStaticQuery, graphql} from "gatsby";
import BackgroundImage from 'gatsby-background-image'

function MineServices(props) {
    const data = useStaticQuery(
        graphql`
            query {
                topleft: file(relativePath: { eq: "rudnik/vodeni-ogledi-glavnega-rova-v-rudniku-litija.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 800) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }

                topright: file(relativePath: { eq: "rudnik/vodeni-ogledi-izvozni-rov.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 800) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }

                bottomleft: file(relativePath: { eq: "rudnik/skrat-3.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 800) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }

                bottomright: file(relativePath: { eq: "rudnik/kulinarika-v-rudniku-sitarjevec-sitarjevska-malca-in-skratov-smorn.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 800) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `
    );

    const topLeftImage = data.topleft.childImageSharp.fluid;
    const topRightImage = data.topright.childImageSharp.fluid;
    const bottomLeftImage = data.bottomleft.childImageSharp.fluid;
    const bottomRightImage = data.bottomright.childImageSharp.fluid;

    return (
        <div className="rn-blog-area ptb--120 bg_color--5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-12 mx-auto">
                        <div className="section-title text-center">
                            <h2 className="title">A wide range of experiences of the Sitarjevec mine</h2>
                            <p className="description">The Sitarjevec Litija mine is a natural science and history classroom in a natural environment. It stirs curiosity and a unique experience, while at the same time awakens awareness and consolidates values about our rich heritage, which is becoming world-famous through stories.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row mt--30 mt_sm--40">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <Link to="/en/visit-the-mine/the-main-pit">
                            <div className="im_box mt--30">
                                <div className="thumbnail">
                                    <BackgroundImage Tag="div" className="service-details-content" fluid={topLeftImage} />
                                </div>
                                <div className="content">
                                    <div className="inner">
                                        <div className="content_heading">
                                            <div className="category_list">
                                                Guided tours
                                            </div>
                                            <h4 className="title">
                                                <Link to="/en/visit-the-mine/the-main-pit">Guided Tour of the Main Pit</Link>
                                            </h4>
                                        </div>
                                        <div className="content_footer">
                                            <Link to="/en/visit-the-mine/the-main-pit" className="rn-btn btn-opacity">Learn more</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <Link to="/en/visit-the-mine/the-export-pit">
                            <div className="im_box mt--30 service-details-container">
                                <div className="thumbnail">
                                    <BackgroundImage Tag="div" className="service-details-content" fluid={topRightImage} />
                                </div>
                                <div className="content">
                                    <div className="inner">
                                        <div className="content_heading">
                                            <div className="category_list">
                                                Guided tours
                                            </div>
                                            <h4 className="title">
                                                <Link to="/en/visit-the-mine/the-export-pit">Guided Tour of the Export Pit</Link>
                                            </h4>
                                        </div>
                                        <div className="content_footer">
                                            <Link to="/en/visit-the-mine/the-export-pit" className="rn-btn btn-opacity">Learn More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <Link to="/en/visit-the-mine/animated-tours">
                            <div className="im_box mt--30 service-details-container">
                                <div className="thumbnail">
                                    <BackgroundImage Tag="div" className="service-details-content" fluid={bottomLeftImage} />
                                </div>
                                <div className="content">
                                    <div className="inner">
                                        <div className="content_heading">
                                            <div className="category_list">
                                                Guided tours
                                            </div>
                                            <h4 className="title">
                                                <Link to="/en/visit-the-mine/animated-tours">Animated Guided Tour For a Family With a Dwarf</Link>
                                            </h4>
                                        </div>
                                        <div className="content_footer">
                                            <Link to="/en/visit-the-mine/animated-tours" className="rn-btn btn-opacity">Learn More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <Link to="/en/visit-the-mine/culinary">
                            <div className="im_box mt--30 service-details-container">
                                <div className="thumbnail">
                                    <BackgroundImage Tag="div" className="service-details-content" fluid={bottomRightImage} />
                                </div>
                                <div className="content">
                                    <div className="inner">
                                        <div className="content_heading">
                                            <div className="category_list">
                                                Guided tours
                                            </div>
                                            <h4 className="title">
                                                <Link to="/en/visit-the-mine/culinary">Culinary</Link>
                                            </h4>
                                        </div>
                                        <div className="content_footer">
                                            <Link to="/en/visit-the-mine/culinary" className="rn-btn btn-opacity">Learn more</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MineServices;