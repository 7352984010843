import React from 'react';
import { FiCheck, FiArrowUpRight } from "react-icons/fi";
import { Link } from 'gatsby';
import {StaticImage} from "gatsby-plugin-image";

function MineServicesDetails(props) {
    return (
        <div>
            <div className="rn-service-details ptb--120 bg_color--0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">
                                    {/* Start Single Area */}
                                    <div className="row row--35 service-details-content align-items-center">
                                        <div className="col-lg-6 col-12">
                                            <div className="details p-2 p-lg-4">
                                                <div className="section-title">
                                                    <h2 className="title">Guided Tours For All</h2>
                                                    <p className="description">
                                                        The advantage of the Sitarjevec mine is that everyone can see the underground world – access is easy. You can see the priceless wealth and beauty of the mine at the first 200 meters
                                                        of the <Link to="/en/visit-the-mine/the-export-pit">Export pit</Link>. A visit can also be an unforgettable experience for the whole family if the dwarf Sitarjevček joins the visit.
                                                    </p>

                                                    <p className="description">
                                                        For everyone who wants a little more excitement with the initial experience of the underground, we recommend the visit of the <Link to="/en/visit-the-mine/the-main-pit">Main pit</Link>. This part of the mine is also easily accessible. Just 17 meters from the Export pit and you will find yourself in a colourful cave paradise for all curious explorers of natural beauty, not just geologists.
                                                    </p>

                                                    <ul className="list-style--1 mt--30">
                                                        <li>
                                                            <FiCheck />
                                                            <Link to="/en/price-list" className="action-link">Guided tours</Link>
                                                        </li>
                                                        <li>
                                                            <FiCheck />
                                                            <Link to="/en/visit-the-mine/animated-tours" className="action-link">
                                                                Animated guided tour with the dwarf Sitarjevček
                                                            </Link>
                                                        </li>

                                                        <li>
                                                            <FiCheck />
                                                            <Link to="/en/visit-the-mine/animated-tours" className="action-link">
                                                                Fairy tales and Sitarjevec stories
                                                            </Link>
                                                        </li>

                                                        <li>
                                                            <FiCheck />
                                                            <Link to="/en/visit-the-mine/culinary" className="action-link">
                                                                Sitarjevec snack and Dwarf “šmorn”, Dwarf shredded pancake
                                                            </Link>
                                                        </li>

                                                        <li>
                                                            <FiCheck />
                                                            <a href="/en/visit-the-mine/the-export-pit/" target="_blank" className="action-link">
                                                                Tourist packages for trip participants of all generations
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <FiCheck />
                                                            <a href="https://visitlitija.si/izleti/" target="_blank">
                                                                Cycling trips with a visit to the mine
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12 mt_md--30 mt_sm--30">
                                            <div className="thumb position-relative">
                                                <StaticImage
                                                    src="../../assets/images/rudnik/vodeni-ogledi-za-vse.jpg"
                                                    alt="Visits for all"
                                                    loading="lazy"
                                                    placeholder="blurred"
                                                    imgClassName="img-fluid w-100"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Area */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}
        </div>
    );
}

export default MineServicesDetails;