import React from 'react';
import {StaticImage} from "gatsby-plugin-image";
import { Link } from 'gatsby';

function MineEvents(props) {
    return (
        <div>
            <div className="portfolio-related-work ptb--120 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <div className="section-title text-center">
                                <h2 className="title">Traditional Events</h2>
                                <p>As part of the New Year's events of the Magical Litija, there is a variety of events in and in front of the Sitarjevec mine. The events are well attended and attract more visitors every year. Some events invite with differentness and uniqueness, others with nostalgia and a pleasant atmosphere.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row mt--10">
                        {/* Start Single Portfolio */}
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="related-work text-center mt--30">
                                <div className="thumb hover-slide-up">
                                    <Link to="/en/visit-the-mine/events">
                                        <StaticImage
                                            src="../../assets/images/rudnik/mineralne-jaslice.jpeg"
                                            alt="Mineral nativity scene "
                                            placeholder="blurred"
                                            imgClassName="img-fluid w-100"
                                            loading="lazy"
                                        />
                                    </Link>
                                </div>
                                <div className="inner">
                                    <h4><Link to="/en/visit-the-mine/events">Mineral nativity scene</Link></h4>
                                    <span className="category">December</span>
                                </div>
                            </div>
                        </div>

                        {/* Start Single Portfolio */}
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="related-work text-center mt--30">
                                <div className="thumb hover-slide-up">
                                    <Link to="/en/visit-the-mine/events">
                                        <StaticImage
                                            src="../../assets/images/rudnik/pohod-z-baklami.jpeg"
                                            alt="Torchlight walk"
                                            placeholder="blurred"
                                            imgClassName="img-fluid w-100"
                                            loading="lazy"
                                        />
                                    </Link>
                                </div>
                                <div className="inner">
                                    <h4><Link to="/en/visit-the-mine/events">Torchlight walk</Link></h4>
                                    <span className="category">December</span>
                                </div>
                            </div>
                        </div>

                        {/* Start Single Portfolio */}
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="related-work text-center mt--30">
                                <div className="thumb hover-slide-up">
                                    <Link to="/en/visit-the-mine/events">
                                        <StaticImage
                                            src="../../assets/images/rudnik/zive-jaslice.jpeg"
                                            alt="Live nativity scene"
                                            placeholder="blurred"
                                            imgClassName="img-fluid w-100"
                                            loading="lazy"
                                        />
                                    </Link>
                                </div>
                                <div className="inner">
                                    <h4><Link to="/en/visit-the-mine/events">Live nativity scene </Link></h4>
                                    <span className="category">December</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MineEvents;