import React from 'react';
import {StaticImage} from "gatsby-plugin-image";

function NewsSimple() {
    return (
        <div className="portfolio-related-work ptb--120" id="news">
            <div className="about-wrapper p-4 p-md-0">
                <div className="container">
                    <div className="row row--35">
                        <div className="col-lg-12">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    <span className="primary-subtitle">Announcements</span>
                                    <h2 className="title">Current News From the Underground</h2>
                                </div>
                                <div className="row mt--30">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="single-tab-content mt-4">
                                                    <div className="row">
                                                        <div className="col-md-7 pr-5 pt-4">

                                                            <p className="text-primary text-uppercase mb--0 fontWeight400"
                                                               style={{"fontSize": "0.875rem"}}>
                                                                Sitarjevec Litija Mine – Friday and Saturday, 11 - 12 October 2024
                                                            </p>

                                                            <h3>
                                                                Ethnological event in front of the Sitarjevec mine Litija - Iron Age iron mining
                                                            </h3>

                                                            <p className="mb-15px text-primary">
                                                                Join us for a unique ethnological event in front of the Sitarjevec Litija mine, where we will
                                                                demonstrate how iron ore was extracted in the Iron Age.
                                                            </p>

                                                            <p className="mb-15px">
                                                                You will be able to watch the whole process on the platform in front of the mine between 9:00 and
                                                                14:00 on Saturday 12 October. There will be a cultural programme with Celtic music at 11:00 and
                                                                12:00. Ana Novak and Gašper Šinkovec, members of the ethno band Noreia, will provide a pleasant
                                                                atmosphere.
                                                            </p>

                                                            <p className="mb-15px">
                                                                The event will be further enriched by a selection of handicrafts and culinary offerings. During the event, it will be possible to visit the mine.
                                                            </p>

                                                            <p className="mb-15px">
                                                                For more information, please contact us at info@visitlitija.si or 051 312 739 (Mojca Hauptman). You are invited to join us and experience the heritage of our ancestors in a very special way!
                                                            </p>

                                                            <a
                                                                href="https://spletnesi.fra1.cdn.digitaloceanspaces.com/rudnik-sitarjevec/Ethnological%20events%20in%20front%20of%20the%20Sitarjevec%20mine%20Litija.pdf"
                                                                className="btn-default size-md mt-2"
                                                                target="_blank"
                                                            >
                                                                Read More
                                                            </a>
                                                        </div>

                                                        <div
                                                            className="col-md-5 flex justify-content-start align-items-start mt-3">
                                                            <div className="thumb hover-slide-up">
                                                                <StaticImage
                                                                    src="../../assets/images/rudnik/etnoloski-dogodek.png"
                                                                    alt="Etnološki dogodek 2024"
                                                                    placeholder="blurred"
                                                                    imgClassName="img-fluid w-100"
                                                                    loading="lazy"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewsSimple;