import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {StaticImage} from "gatsby-plugin-image";

function Testimonial(props) {
    return (
        <div className="rn-testimonial-area bg_color--3 ptb--120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row align-items-center py-4 py-md-0">
                                <div className="col-lg-8">
                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>The Sitarjevec mine is no longer just a memory of the past times. We preserve it as a precious natural value of national importance and an interesting technical heritage. In its renewed form, it serves a tourist-educational purpose and presents a challenge for the development of innovative research procedures.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>DR. Tea Kolar-Jurkovšek </span> - Scientific advisor of the Geological Institute of Slovenia</h6>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </div>
                                <div className="col-lg-4 mt_md--40 mt_sm--40 d-none d-lg-block">
                                    <div className="testimonial-person ">
                                        <StaticImage
                                            src="../../assets/images/rudnik/tea-kolar-jurkovsek.jpg"
                                            alt="DR. Tea Kolar-Jurkovšek - Scientific advisor of the Geological Institute of Slovenia"
                                            loading="lazy"
                                            placeholder="blurred"
                                            imgClassName="border-radius-full border-shadow-1"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Testimonial;